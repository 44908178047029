import styled from 'styled-components';
import { Sparkle } from './Sparkle';
import { X } from './X';
import { useState } from 'react';

const Container = styled('div')`
  display: flex;
  column-gap: 40px;
  justify-content: center;
  background-color: #6e54cb;
`;

const Container2 = styled('div')`
  max-width: 1600px;
  display: flex;
  column-gap: 40px;
  align-items: flex-start;
  padding-block: 24px;
  padding-inline: 16px;

  @media (min-width: 950px) {
    padding-inline: 120px;
  }
`;

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  column-gap: 40px;

  @media (min-width: 850px) {
    flex-direction: row;
  }
`;

const Headline = styled('div')`
  position: relative;
  flex-shrink: 0;
  padding-block-end: 1rem;
`;

const HeadlineTextContainer = styled('div')`
  padding-inline-start: 1.8rem;
  color: #fff;
  font-family: Satoshi-Bold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`;

const SparkleContainer = styled('div')`
  position: absolute;
  top: -3px;
  left: 0;
`;

const Info = styled('div')`
  color: #fff;
  font-family: DMSans-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
`;

export function NakamotoReopeningBanner() {
  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) return null;

  return (
    <Container>
      <Container2>
        <ContentContainer>
          <Headline>
            <SparkleContainer>
              <Sparkle />
            </SparkleContainer>
            <HeadlineTextContainer>We're Back & Better!</HeadlineTextContainer>
          </Headline>
          <Info>
            <p style={{ paddingBottom: '1rem' }}>
              Xverse Pool has reopened with updated contracts post-Nakamoto upgrade. You can now
              delegate to the new contract for the upcoming cycle. We appreciate your continued
              trust as we enhance our services.
            </p>
          </Info>
        </ContentContainer>
        <div
          onClick={() => setIsOpen(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsOpen(false);
            }
          }}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
        >
          <X />
        </div>
      </Container2>
    </Container>
  );
}
